button:
  about: Haqida
  back: Orqaga
  go: Kettik
  home: Bosh sahifa
  toggle_dark: Qorong‘i rejimga o‘tish
  toggle_langs: Tilni o‘zgartirish
intro:
  desc: O‘ylangan boshlang‘ich Vite shabloni
  dynamic-route: Dynamic route demo'si
  hi: Assalomu alaykum, {name}!
  aka: shuningdek
  whats-your-name: Ismingiz nima?
not-found: Topilmadi
